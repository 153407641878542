import { GRichText } from "@/components";
import { CarouselBanners as CarouselBannersBase, Link, NextImage, alignMap } from "@jog/react-components";
import { asLink, isFilled } from "@prismicio/helpers";
import classNames from "classnames";
const CarouselItem = ({ className, item, }) => {
    var _a, _b;
    return (<div className={classNames(className)}>
        <Link to={asLink(item.banner_link)} className="relative block">
            <NextImage width={((_a = item.banner_image.dimensions) === null || _a === void 0 ? void 0 : _a.width) || 130} height={((_b = item.banner_image.dimensions) === null || _b === void 0 ? void 0 : _b.height) || 200} className="h-auto w-full" src={item.banner_image.url} alt={item.banner_image.alt}/>
            <div className="absolute left-0 right-0 bottom-0 m-2.5">
                <div style={{ backgroundColor: item.banner_bg_color }} className="inline-block px-2 py-1">
                    {isFilled.richText(item.banner_title) && (<div style={{ color: item.banner_title_color }}>
                            <GRichText field={item.banner_title} paragraphClass="mb-0 text-sm"/>
                        </div>)}
                    {isFilled.richText(item.banner_subtitle) && (<div style={{ color: item.banner_subtitle_color }}>
                            <GRichText field={item.banner_subtitle} paragraphClass="mb-0 text-sm"/>
                        </div>)}
                </div>
            </div>
        </Link>
    </div>);
};
export const CarouselBanners = ({ slice: { primary, items } }) => {
    const displayNumber = +primary.display_number || 5;
    const alignment = alignMap[primary.alignment] || alignMap.left;
    return (<div className="mb-1 px-2.5 pb-1 text-gray-base lg:mb-2.5 lg:px-0" style={{ backgroundColor: primary.background_color }}>
            {isFilled.richText(primary.title) && (<div className={classNames("mb-2 pt-5 text-3xl font-bold", alignment)} style={{ color: primary.title_color }}>
                    <GRichText field={primary.title}/>
                </div>)}
            {isFilled.richText(primary.descriptions) && (<div className={classNames("mb-2 text-base", alignment)} style={{ color: primary.description_color }}>
                    <GRichText field={primary.descriptions} paragraphClass="text-sm mb-0"/>
                </div>)}
            <CarouselBannersBase CarouselItem={CarouselItem} displayNumber={displayNumber} items={items}/>
        </div>);
};
