import { GRichText } from "@/components";
import { isFilled } from "@prismicio/helpers";
import classNames from "classnames";
const textAlign = {
    center: "text-center",
    left: "text-left",
    right: "text-right",
};
export const TextHeadline = ({ slice: { items, primary } }) => (<div className={classNames("py-7 px-5 text-black", textAlign[primary.text_align] || textAlign["center"])}>
        {items
        .filter((i) => isFilled.richText(i.rich_text_line))
        .map((item, index) => (<div key={index}>
                    <GRichText field={item.rich_text_line}/>
                </div>))}
    </div>);
