import { GRichText } from "@/components";
import { Link } from "@jog/react-components";
import { isFilled } from "@prismicio/helpers";
import { LinkType } from "@prismicio/types";
import classNames from "classnames";
export const Buttons = ({ slice: { primary, items }, className }) => {
    const isHorizontal = primary.arrangement !== "vertical arrangement";
    return (<div className={className} style={{
            marginTop: primary.margin_top || undefined,
            marginBottom: primary.margin_bottom || undefined,
        }}>
            <div className={classNames("flex flex-wrap gap-4", isHorizontal ? "flex-row" : "flex-col", {
            "justify-center": isHorizontal && (primary.alignment === "center" || !primary.alignment),
            "justify-start": isHorizontal && primary.alignment === "left",
            "justify-end": isHorizontal && primary.alignment === "right",
            "items-center": !isHorizontal && (primary.alignment === "center" || !primary.alignment),
            "items-start": !isHorizontal && primary.alignment === "left",
            "items-end": !isHorizontal && primary.alignment === "right",
        })}>
                {items.map((item, index) => {
            var _a;
            return (<button key={index} className="inline-block border py-2 px-4" style={{
                    borderColor: item.border_color || undefined,
                    backgroundColor: item.bg_color || undefined,
                }}>
                        <Link style={{
                    color: item.text_color || undefined,
                }} href={isFilled.link(item.button_link) ? item.button_link.url : "/"} target={isFilled.link(item.button_link) && item.button_link.link_type === LinkType.Web
                    ? (_a = item.button_link) === null || _a === void 0 ? void 0 : _a.target
                    : "_self"}>
                            {item.button_font_size_for_mobile ? (<>
                                    <div className="hidden lg:block">
                                        <GRichText field={item.button_text} paragraphClass="text-base"/>
                                    </div>
                                    <div className="block lg:hidden">
                                        <GRichText paragraphStyle={{
                        fontSize: item.button_font_size_for_mobile,
                    }} field={item.button_text} paragraphClass="text-base"/>
                                    </div>
                                </>) : (<GRichText field={item.button_text} paragraphClass="text-base"/>)}
                        </Link>
                    </button>);
        })}
            </div>
        </div>);
};
